import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const Media = () => {
  return (
    <>
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Gallery <i className="fas fa-angle-right"></i>  Media </span></h5>
        <h2>Media</h2>
        </div>
        <div className="row">
        <div className="row img_data_sec">
        <div className="col-xl-3 col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Media-03-03-2020-2.jpg">
            <img src="Images/Media-03-03-2020-2.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Annual Day</h4></div></div> 
          <div className="col-xl-3 col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Media-03-03-2020-3.jpg">
              <img src="Images/Media-03-03-2020-3.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Sports & Culture</h4></div></div> 
          <div className="col-xl-3 col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Media-04-02-19.jpg">
              <img src="Images/Media-04-02-19.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Lotus Valley</h4></div></div> 
              <div className="col-xl-3 col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Media.jpg">
              <img src="Images/Media.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>Orientation Day</h4></div></div> 
              <div className="col-xl-3 col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Media-03-03-2020-1.jpg">
              <img src="Images/Media-03-03-2020-1.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a><h4>School Buzz</h4></div></div> 
  </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Media
