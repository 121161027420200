import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const Curriculum = () => {
  return (
    <>
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Academics <i className="fas fa-angle-right"></i> Curriculum </span></h5>
        <h2>Curriculum</h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
             
               <img src="Images/about.jpg" className="inner_img img-fluid"  alt="Lotus Valley International School, Greater Noida"/>
               <p>Curriculum at LVIS, NE is a sum total of educational experiences with clear Learning Objectives, Courses of Study, Pedagogical Practices and Assessment guidelines. It seeks to provide opportunities to learners for physical, intellectual and social development; enhance self-awareness and explore innate potential; promote life skills & inculcate values; strengthen knowledge, skills attitude & promote physical fitness, health & wellbeing.  </p>  
<h3>Pre-Primary</h3>
<p>The early years play a key role in children's brain development. Children's early experiences, the bonds they form with their parents and their first learning experiences, deeply affect their future physical, cognitive, emotional and social development.</p>  
<p>Enabling environment plays a key role in supporting and extending children’s development and learning. When children feel emotionally safe and secure, they are able to explore and find out about the place they are in and the things they can see, touch, or manipulate.</p>  
<p>Simple games of peek-a-boo, shaking a rattle or singing a song are much more important than just a way to pass the time. They teach young children about communication, develop their motor skills and help with problem-solving. Something as easy as stacking and knocking over blocks allows toddlers to discover math and science concepts, including shapes, gravity, balance and counting.</p>  
<p>These early childhood games are vital to laying the foundations for formal education. To provide our learners ample opportunities to explore, discover, experiment and enjoy, different activities and games form the core of our curriculum. Every activity provides a way for children to build their understanding of new concepts. Through play, they discover the world and learn how things work as they explore and investigate. Children develop skills such as memory, perception, problem-solving, thinking skills etc. There is no better way children learn to socialize than during play as well as develop language skills. It is an excellent way to safely express their feelings and make sense of their world while playing. Various activities are conducted to engage the Pre- Primary learners to explore and experiment.  The learners enjoy sand play, nature walk and outdoor play to enhance their motor skills and social skills. </p>  
<p>Children are provided the materials to engineer and build three-dimensional solutions. Opportunities for drawing and painting and access to an assortment of materials and the freedom for safe exploration spark creative thinking and problem solving.</p>  
<p>The primary curriculum is designed to address the following-</p>  
<p><strong> Language and Literacy Development – </strong>Language development is crucial at the Pre-Primary as it lays the foundation for later learning. Language development involves listening skills & vocabulary development; pre literacy skills such as phonics & sentences. Hearing new vocabulary spoken helps prepare children to identify unfamiliar words from context, as well as increase their overall phonological awareness. </p>
<p>The Alphabet is taught using Jolly phonics with actions emphasizing on the sounds of the letters. The letter formation and word formations are done through rainbow writing, tracing on varied things such as sand, flour and paint. Activities such as poem recitation, assemblies, show and tell related to colours, numbers, concepts etc. are carried out on a regular basis. Story sessions are regularly conducted to enhance the vocabulary and the listening skills of the learners. Word Webs and Augmented reality are incorporated to tickle their curiosity and bring the world to the classroom. </p>  
<p><strong>Cognitive Development : </strong> To help children to develop their cognitive skills, focus on creating an environment where a child feels comfortable asking questions about the world and the processes that govern it. Asking open-ended questions also encourages a sense of exploration, critical thinking observation and problem solving.</p>  
<p>Pre-mathematical concepts such as heavy or light etc. with quantification and association are taken at varied stages. Activities such as colour/number hopscotch, addition relay race number bingo are also conducted.</p>  
<p>Emphasis is also laid to providing experiences to the learners relating the concepts done with the real world. Displays and pretend play related to transport, seasons, camping, culinary delights are conducted. The learners are taken for visiting different places such as grocery shop, Mother Dairy and parks to provide experiential learning. </p>  
<p><strong>Physical and Motor Development - </strong>Physical and Motor Development skills are essential to healthy growth and development and success in school. Motor development focuses on small (turning pages, holding a crayon, building with legos, cutting with scissors) and large (crawling, running, bouncing a ball) movement skill.</p>  
<p>Activities such as lemon race, hopscotch activities, music and movement etc. are integral part of the curriculum. Brain breaks, yoga and simple activity of enjoying on the school grounds are taken up. To enhance fine motor skills, activities such potato peeling, shelling peas, lacing, peg board puzzles, colouring, painting, paper crumpling and craft activities are encouraged.</p>  
<p><strong>Social and Emotional Development - </strong> Success in school is not just about academics. It’s also about developing the skills to relate to peers and educators. Social and emotional development means how children start to understand who they are, what they feel and what to expect when interacting with others. It is the development of being able to form and sustain positive relationships, experience, manage and express emotions and explore and engage with the environment. </p>  
<p>Circle time and free play are important aspects of the day. The learners are provided time to play anything of their choice thus providing them an opportunity to take decisions as well as interact with their peers. Circle time provides them an opportunity to share their thoughts, experiences and simply be a part of the class community. Celebrations on various festivals as well as events are conducted to foster a sense of community. </p> 
<h3>Primary</h3>
<p>The Primary school equips and provides each student the possibilities to discover their strengths and refrain the joy of learning. The school empowers the students with an experience of holistic learning, which enables them to imbibe important life skills along with the understanding of the real-world situations and the needs of a sustainable world for the future. The school focuses on the 21st Century skills while giving individual attention and celebrating the uniqueness of each child. </p>  
<p>We at LVIS, NE believe in learning by doing thus all the subjects across the curriculum follow a hands-on, enquiry based experiential approach with well-defined learning objectives and outcomes. </p>  
<p>While teaching <strong> Science</strong> to primary students the main objective is to develop curiosity, interest and enjoyment towards science and its methods of inquiry. It also aims at developing love for nature, to conserve natural resources and prevent pollution thus connecting knowledge to life outside the school. To remove the fear of <strong>Mathematics</strong>, teachers use a lot of online tools for better understanding of the subject. The main aim of teaching maths at primary level is to enable the students to develop a connection between daily life contexts and mathematical thinking, understand concepts and articulate their observable properties as similarities and differences among them. They learn to represent the part of a whole as a fraction and order simple fractions, collect, represent and interpret simple data from her/his contexts and use it in everyday life. Through maths lab activities students get a hands-on experience of solving such problems.</p>  
<p>The primary goal of the junior school <strong> language</strong> curriculum both <strong>Hindi </strong>and <strong>English</strong> is to empower students to communicate and express themselves confidently. It aims at students to acquire the skills of listening, speaking, reading, writing and thinking in an integrated manner.</p>  
<p> The school takes pride in its <strong>‘DEAR’</strong> programme wherein students are exposed to reading of books in different genres. This engages the students in critical thinking and reflection as they read, discuss, and respond to books.</p>  
<p><strong>Social Studies</strong> curriculum aims to develop attitudes which promotes responsible citizenship and democratic values, respect for human dignity and rights, acquire awareness about immediate/wider surroundings through lived experiences on various themes related to daily life, e.g., family, plants, animals, food, water, travel, and shelter etc. Nurture natural curiosity and creativity for the immediate surroundings, develop sensitivity for the natural, physical and human resources in the immediate environment. </p>  
<p>The school espouses pride in one’s motherland through its <strong>‘Discover India’</strong> programme which focuses on exploring, understanding and experiencing the rich heritage, natural resources, traditions and culture of India. </p>  
<p>The school engages the students in <strong>Visual and Performing Arts</strong> at this level and encourages them to create, design and express themselves through this medium. This enables the students to think critically and creatively.</p>  
<p><strong>Sports</strong> is taken up very seriously from a young age at LVIS, NE. Students are encouraged to take part in all sports offered by the school such as cricket, football, taekwondo, chess, table tennis, basketball, skating etc. at primary level.</p>  
<h3>Middle school</h3>
<p>The middle school curriculum acknowledges the fact that subjects like languages, Mathematics, Social Science & Science help the cognitive development of the child & therefore, require a greater academic emphasis. Further the school takes cognizance of the fact that integration of co-curricular domains with curricular activities ensures holistic approach to education. At this stage pace of academic demands is accelerated and, while holistic growth is always the focus, students are gradually prepared to accept bigger challenges by getting support in acquiring life skills. This is also the time wherein students learn to build on basics. A simple example being Science branching out to separate subjects like Physics, Chemistry, and Biology as soon as students enter the middle school stage. Here, students start refining their knowledge hinged on the basics they already know and the difficulty level naturally turns up by a notch. The Middle school program at LVISNE has children at its core. They are assisted at every step with their learning of different subjects, with continuous efforts and practice in the form of formative assessments, periodic tests and summative assessments. Students gain confidence in writing examinations. </p>  
<p>Projects are an integral part of experiential learning and help children observe, reflect, analyse, synthesize and understand multiple perspectives. As children engage with ‘UN Sustainable Development Goals’ while studying new concepts, they relate ideas, comprehend underlying issues and discuss potential resolutions. Students also get many opportunities through inter and intra school events to explore many options, participate in niche areas of interest and develop specific skill-sets. They maintain portfolios of their learning in all fields which includes their performances and achievements inside and outside the school.</p>  
<ul>
<li><strong>Subjects offered </strong>at the Middle School Level are English, Mathematics, Science (Physics Chemistry and Biology), Social Studies (History, Civics and Geography), Sanskrit / French/German (Third language), Computer Applications</li>
<li><strong>STEAM Lab:</strong> An Innovation center where Art is integrated with Science, Technology, Engineering and Mathematics</li>
<li><strong>Languages: </strong>The focus areas in languages are LSRW (Learning, Speaking, Reading, and Writing). Students acquire language proficiency and communicate effectively.</li>
<li><strong>Mathematics: </strong>The curriculum focus is on numbers, operations, computation & geometry and also on application of the above skills in daily life. Learners also learn to develop patterns, decode information and problem solve. </li>
<li><strong>Social Science:</strong> The curriculum aims to make learners know and understand the culture, geographical & historical context and acquire knowledge, skill and attitudes. They also understand the concept of citizenship and their fundamental Rights and Duties as citizens.</li>
<li><strong>Science:</strong> The focus is on developing scientific temperament and spirit of inquiry. The knowledge & skills acquired are to be applied for creating a better tomorrow.</li>
</ul>

<p><strong>Visual and Performing Arts:</strong></p>
<ul>
<li>Indian and Contemporary Art</li>
<li>Vocal and Instrumental Music</li>
<li> Indian and Western Dance</li>
<li>Theatre</li>
</ul>
<h3>Co-curricular Activities</h3>
<p>Physical Education and Sports activities are an integral aspect of growing and learning. At the middle school level, students take up sports of their choice in which they wish to specialize. Their progress is monitored thoroughly by the coaches, who help identify talents in various sports and encourage them to take part in various intra & inter school competitions. Fitness is a key focus area not only for students, but also for parents and teachers. Our fitness weeks, yoga assemblies, sports extravaganza, friendly sports matches with parents, make PE and Sports inseparable to education.</p>

  
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Curriculum
