import React from 'react'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="erp_links">
         <Link to="#" target="_blank"><img src="Images/Campulogo.png" className="img-fluid float-end campus" alt="Lotus Valley International School, Greater Noida"/></Link>
      </div>
      </>
        
  )
}

export default Campuscare
