import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom'
const QuickGlancesixslide = () => {
  const options = {
    nav: false,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
     };
  return (
    <>
      <OwlCarousel className="owl-carousel" {...options}>
        <div className="item">
        <div className="draw-border">
                    <h3><Link to="ArtCraft">Art & Craft</Link></h3>
                    <p>Art is a diverse range of human activities involving the creation of visual, auditory or performing artifacts (artworks), which express the creator's imagination</p>
                    <div className="clr"></div>
                  </div>
        </div>
        <div className="item">
          <img src="Images/art.jpg" className="image" alt="Lotus Valley International School, Greater Noida"/>
        </div>
      </OwlCarousel>
    </>
  );
};

export default QuickGlancesixslide;
