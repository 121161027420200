import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getGalleryInner } from "../Service/Api";

const HomeGallery = () => {
  const [data, setData] = useState([]); // State for gallery data
  const [loading, setLoading] = useState(true); // State for loading indicator
  const [error, setError] = useState(null); // State for error handling

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGalleryInner();
        const filteredData = galleryData
          .filter((item) => item.category === 'PhotoGallery')
          .slice(0, 6); // Only take the latest 6 entries
        console.log('Filtered Data:', filteredData); // Debug: Check fetched data
        setData(filteredData);
      } catch (error) {
        setError("Error fetching gallery data");
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    return `${day}.${month}.${year}`;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (data.length === 0) {
    return <p>No gallery data available.</p>;
  }

  const firstImage = data[0];
  const smallImages = data.slice(1, 3);
  const sideImages = data.slice(3, 6);
  const placeholdersNeeded = 3 - sideImages.length;
  const sideImagesWithPlaceholders = [
    ...sideImages,
    ...Array(placeholdersNeeded).fill(null),
  ];

  console.log('Data Length:', data.length); // Debug: Check total data length
  console.log('Side Images:', sideImages); // Debug: Check side images
  console.log('Side Images with Placeholders:', sideImagesWithPlaceholders); // Debug: Check side images with placeholders

  return (
    <>
      <div className="row">
        <div className="col-lg-8">
          <div className="col-lg-12">
            <div className="homegallerybox">
              <center>
                <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${firstImage.attachments[0]}`} className="big_img img-fluid" alt={firstImage.title} />
              </center>
              <div className="gal_data_sec">
                <div className="date_sec">
                  <Link to={firstImage.link || '#'}>
                    <i className="fas fa-angle-double-right"></i>
                  </Link> {formatDate(firstImage.date)}</div>
                <h4>{firstImage.title}</h4>
                <h5></h5>
              </div>
            </div>
          </div>
          <div className="row">
            {smallImages.map((item, index) => (
              <div className="col-lg-6" key={index}>
                <div className="homegallerybox">
                  <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="img-fluid" alt={item.title} />
                  <div className="gal_data_sec">
                    <div className="date_sec">
                      <Link to={item.link || '#'}>
                        <i className="fas fa-angle-double-right"></i>
                      </Link> {formatDate(item.date)}</div>
                    <h4>{item.title}</h4>
                    <h5></h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            {sideImagesWithPlaceholders.map((item, index) => (
              <div className="col-lg-12" key={index}>
                {item ? (
                  <div className="homegallerybox">
                    <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="img-fluid" alt={item.title} />
                    <div className="gal_data_sec">
                      <div className="date_sec">
                        <Link to={item.link || '#'}>
                          <i className="fas fa-angle-double-right"></i>
                        </Link> {formatDate(item.date)}</div>
                      <h4>{item.title}</h4>
                      <h5></h5>
                    </div>
                  </div>
                ) : (
                  <div className="homegallerybox placeholder">
                    <div className="gal_data_sec">
                      <h4>No Data</h4>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeGallery;
