import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const Music = () => {
  return (
    <>
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Co-Curricular  <i className="fas fa-angle-right"></i>  Music</span></h5>
        <h2>Music</h2>
        </div>
        <h3>Music expresses that which cannot be said and on which it is impossible to be silent.</h3>
  
        <div className="row img_data_sec">
        <div className="col-lg-4 col-md-6"><article> <a data-magnify="gallery" data-src="" data-group="a" href="Images/music1.jpg"><img src="Images/music1.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/></a> </article></div>
        <div className="col-lg-4 col-md-6"><article> <a data-magnify="gallery" data-src="" data-group="a" href="Images/music2.jpg"><img src="Images/music2.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/></a> </article></div>
        <div className="col-lg-4 col-md-6"><article> <a data-magnify="gallery" data-src="" data-group="a" href="Images/music3.jpg"><img src="Images/music3.jpg" className="art_craft_img img-fluid mx-auto d-block"  alt="Lotus Valley International School, Greater Noida"/></a> </article></div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Music
