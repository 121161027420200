import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const SchoolTransport = () => {
  return (
    <>
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span>  School Transport</span></h5>
        <h2> School Transport</h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
               
           <img src="Images/bus-1.jpg" className="inner_img img-fluid" alt="Lotus Valley International School, Greater Noida"/>
                 <p>The school has a fleet of Air- conditioned safe and secure buses. The school buses come with an assurance of safety and comfort and are the perfect choice for students and the safety of students is closely monitored at all times by a trained lady attendant in the bus.</p>
                  <p>Each bus is equipped with the following ultra-modern and safe features that provide comfort, convenience and protection to the children travelling in it.</p>
                  <ul>
                    <li> Lowered step for easy boarding of children </li>
                    <li> Extra-large windows with padding and guardrails. </li>
                    <li>Anti-skid flooring, seat belts and grab handles to prevent children from slipping and getting injured.</li>
                  </ul>
            </div>
          </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default SchoolTransport
