import React, { useEffect } from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const FeeStructure = () => {

 
    useEffect(() => {
      // Set the page title
      document.title = 'Fee Structure - Lotus Valley Greater Noida'; // Change 'Your Website Name' to your actual website name
    }, []);

  return (
    <>
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Admission <i className="fas fa-angle-right"></i>  Fee Structure</span></h5>
        <h2>  Fee Structure  (2025-26) </h2>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
            <div class="innertable">
        <table width="100%" cellpadding="0" cellspacing="0">
          <thead>
            <tr class="active">
             <th  style={{width:"50%"}}>PARTICULARS </th>
            <th  style={{width:"50%"}}>AMOUNT (Rs.) </th>
            </tr>
          </thead>
           <tbody>
            <tr>
              <td>Registration Fee</td>
              <td>Rs. 1,000 </td>
            </tr>
            <tr>
              <td>Admission Fee (One Time Payment - Non Refundable)</td>
              <td>Rs. 40,000</td>
            </tr>
            <tr>
            <td>Caution Money (Refundable)</td><td>Rs. 10,000</td>
            </tr>
            <tr><td><b>TUITION FEE</b></td><td><b>MONTHLY/QUARTERLY</b></td></tr>
            <tr><td>NUR - V	</td><td>Rs.10,310/30,930</td></tr>
            <tr><td>VI-X</td><td>Rs.10,750/32,250</td></tr>
            <tr><td>XI - COMMERCE & HUMANITIES</td><td>	Rs.12,150/36,450</td></tr>
            <tr><td>XI - SCIENCE</td><td>	Rs.12,420/37,260</td></tr>
            <tr><td>XII - COMMERCE & HUMANITIES	</td><td>Rs.12,150/36,450</td></tr>
            <tr><td>XII -  SCIENCE</td><td>	Rs.12,420/37,260</td></tr>




              <tr><td><b>TRANSPORT FEE (Optional)</b></td><td><b>MONTHLY/QUARTERLY</b></td></tr>
            <tr><td>Noida Extension</td><td>Rs.3,300/9,900</td></tr>
            <tr><td>Noida</td><td>Rs.4,350/13,050</td></tr>
            <tr><td>Ghaziabad</td><td>	Rs.4,500/13,500</td></tr>
            <tr><td>Crossing Republik</td><td>	Rs.4,150/12,450</td></tr>
            <tr><td>Greater Noida</td><td>Rs.4,200/12,600</td></tr> 


          </tbody>
        </table>
      </div>
      <p><b>Note: The school offers two options for paying the fee, i.e. monthly or quarterly.</b></p>
            </div>
        </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default FeeStructure
