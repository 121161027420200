import React from 'react'
import { Link } from 'react-router-dom'
const OnlineFee = () => {
  return (
     <>
     <div class="online_fee"> <Link to="https://www.lviscampuscare.net/Logon/TPLoginRegistration" target="_blank"><img class="" src="Images/enquire.png" alt="Lotus Valley International School, Greater Noida"/></Link>
    <div class="clr10"></div>
    <Link to="https://www.lviscampuscare.net/" target="_blank"><img class="" src="Images/online_payment.gif" alt="Lotus Valley International School, Greater Noida"/></Link></div>
      </>
        
  )
}

export default OnlineFee
