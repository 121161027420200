import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const MissionVision = () => {
  return (
    <>
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> About Us  <i className="fas fa-angle-right"></i>  Mission & Vision </span></h5>
        <h2> Mission & Vision</h2>
        </div>
        <p><img src="Images/mission.jpg" className="inner_img" alt="Lotus Valley International School, Greater Noida"/></p>
        <p><strong>VISION</strong></p>
        <p>Our vision is to build & nurture a community of passionate and thinking learners who will make a positive impact to the world. We aim to build and nurture a confident, creative and thinking community of learners who strive for excellence, act responsibly and be value-creating global citizens.</p>
         <div className="clr"></div>
          <p><strong>MISSION</strong></p>
        <p>Our mission is to provide a happy, caring, enabling and challenging environment wherein children learn to meet the challenges of a rapidly changing world. We aim to equip our students with knowledge, skills, attitudes and competencies, to help them lead change for a better tomorrow.</p>
      <p>With an eclectic blend of east and west, the innovative, creative, integrated and contemporary curriculum, Lotus Valley International School, Noida Extension is committed to help children discover the joy of learning, to realise their true potential and as UNESCO recommends, “Learning the Treasure Within”</p>
         <div className="clr"></div> 
        </div>
        </div>
     <Footer/>
    </>
  )
}

export default MissionVision
