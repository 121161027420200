import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
    <div className="office_time_sec">
   <div className="container">
       <div className="row">
     <div className="col-lg-6 col-md-12">
 
    <img src="Images/phone-call.png" alt="Lotus Valley International School, Greater Noida"/>
     <h3>Connect With Us</h3>
    <p>		  
          <b>Reception :</b> 0120-4844772 / 8448992087<br/>
 <b>Admissions :</b> 0120-4844771 / 9821694492<br/>
 <b>HR:</b> 09205992385 / <b>Transport:</b> 8448992083</p>

 </div>
 <div className="col-lg-6 col-md-12">
 
    <img src="Images/open-email.png" alt="Lotus Valley International School, Greater Noida"/>
     <h3> Email ID</h3>
   <p>  <Link to="mailto:admissions@lotusvalleynoidaextension.com">admissions@lotusvalleynoidaextension.com </Link> <br/>
            <Link to="mailto:info@lotusvalleynoidaextension.com"> info@lotusvalleynoidaextension.com  </Link><br/>
            <Link to="mailto:principal@lotusvalleynoidaextension.com"> principal@lotusvalleynoidaextension.com </Link> 
           </p>
 
 </div>
     
 </div>
 </div>    
     </div>  
 <div className="footer_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                      <h3>Quick Links</h3>
                      <ul className="quick_links">
                      <li> <Link to="/SchoolTimings">School Timings</Link></li>
                      <li> <Link to="/AdmissionProcess">Admission Process</Link></li>
                      <li> <Link to="/Tc">Transfer Certificate</Link></li>
                      <li> <Link to="/ListHolidays">List Of Holidays</Link></li>
                      <li> <Link to="/SchoolTransport">School Transport</Link></li>
                      </ul>
                      <ul>
                         <li> <Link to="https://www.facebook.com/lvisne" target="_blank"><i className="fab fa-facebook-f"></i></Link></li>
                         <li> <Link to="https://www.youtube.com/channel/UCuuJWWLv9-dgLQ9IX5EZVfQ" target="_blank"><i className="fab fa-youtube"></i></Link></li>
                         <li> <Link to="https://instagram.com/lvis_ne?utm_source=ig_profile_share&amp;igshid=1xz6aqxjbdmzr" target="_blank"><i className="fab fa-instagram"></i></Link></li>
                      </ul>
                   </div>
                   <div className="col-12 col-sm-6 col-md-5 col-lg-5">
                      <h3 className="app_title">Mobile App</h3>
                     
                         <div className="mobile_app">
                            <img src="Images/Mobile-slides.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/>
                         </div>
                         <div className="mobile_app_link">
                             <Link to="https://itunes.apple.com/in/app/campuscare/id1034721587" target="_blank"> <img src="Images/ios.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/> </Link>
                            <div className="clr"></div>
                             <Link to="https://play.google.com/store/apps/details?id=com.campuscare.entab.ui" target="_blank"> <img src="Images/android.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/> </Link>
                            <div className="clr"></div>
                            <h5><b>Use URL :</b> lviscampuscare.net</h5>
                              <ul className="help_file">
                    <li> <Link to="/ParentPortal">Portal Parent?</Link></li>
                    <li> <Link to="/ErpSoftware">ERP Software</Link></li>
                    </ul>
                         </div>
                  
                   </div>
                   <div className="col-12 col-sm-12 col-md-3 col-lg-4 contact_ftr">
                      <h3>Location</h3>
                      <p> <Link to="https://goo.gl/maps/tKL7y31y9nyuXsZa8" target="_blank"> Plot 20 A, Sector Techzone 4, Greater Noida West (Noida Ext.) UP -  201306</Link></p>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.1737113803965!2d77.43252321549468!3d28.594565092548326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceeeff9cce727%3A0x85320429f0e8803a!2sLotus%20Valley%20International%20School!5e0!3m2!1sen!2sin!4v1609565865851!5m2!1sen!2sin" width="100%" height="100" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                     
                      </div>
                    </div>
                </div>
             </div>
      <div className="footer_sec_btm wow fadeInDown">
        <div className="container">
          <p>© Copyright 2018 - 2021 By  Lotus Valley Greater Noida | Created By  <Link  className="link" to="https://www.entab.in/" target="_blank"> Entab Infotech : CampusCare® </Link>  <Link  className="sitemap_sec" to="sitemap.html" target="_blank"> Sitemap </Link></p>
        </div>
      </div>
    </>
  )
}

export default Footer
