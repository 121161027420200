import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const HobbyClubs = () => {
  return (
    <>
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Co-Curricular  <i className="fas fa-angle-right"></i>  Hobby Clubs </span></h5>
        <h2>  Hobby Clubs </h2>
        </div>
        <p>We at LVIS, Noida Extension believe that sports and co-curricular activities play a vital role in the holistic development of children and also form the basis of pre-vocational skills.The school has introduced various Hobby Clubs from the academic session 2018-19 for Classes I-IX. These Clubs will help in identifying and fostering the creativity of students, beyond academics.</p>
        <div className="row">
         <div className="col-lg-6 col-md-12">
        <h3>Classes I & II</h3>
        <div className="innertable">
        <table width="100%;" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody className="text-left">
            <tr>
              <td>1</td>
              <td>Better me</td>
            </tr>      
            <tr className="success">
              <td>2</td>
              <td>Spotlight</td>
            </tr>
            <tr className="danger">
              <td>3</td>
              <td>Happy Feet</td>
            </tr>
            <tr className="info">
              <td>4</td>
              <td>Melody Makers</td>
            </tr>
            <tr className="warning">
              <td>5</td>
              <td>Creative Hive</td>
            </tr>
            <tr className="active">
              <td>6</td>
              <td>Creative Cuisines</td>
            </tr>
               <tr>
              <td>7</td>
              <td>Tech Connect</td>
            </tr>
             <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>      
          </tbody>
        </table>
        </div>
        </div>
        <div className="col-lg-6 col-md-12">
        <h3>Classes III TO V</h3>
        <div className="innertable">
        <table width="100%;" cellpadding="0" cellspacing="0">
      
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody className="text-left">
            <tr>
              <td>1</td>
              <td>Dancepiration – Western Dance</td>    
            </tr>      
            <tr className="success">
              <td>2</td>
              <td>Actattack – Theatre</td>
            </tr>
            <tr className="danger">
              <td>3</td>
              <td>Vox Anima – Western Music</td>
            </tr>
            <tr className="info">
              <td>4</td>
              <td>Checkmate – Chess Club</td>
            </tr>
            <tr className="warning">
              <td>5</td>
              <td>Star Trek – Astronomy (Paid Activity)</td>
            </tr>
            <tr className="active">
              <td>6</td>
              <td>Hola!Amigos! – Spanish Club</td>
            </tr>
               <tr>
              <td>7</td>
              <td>Math Wizard – Math Club</td>
            </tr> 
               <tr>
              <td>8</td>
              <td>Culinary Delight – Fireless cooking</td>
            </tr>      
          </tbody>
        </table>
        </div>
        </div>
        <div className="col-lg-12">
        <h3>Classes VI TO X</h3>
        <div className="innertable">
        <table width="100%;" cellpadding="0" cellspacing="0">
        
          <thead>
            <tr>
              <th>S. No.</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody className="text-left">
            <tr>
              <td>1</td>
              <td>Dancepiration – Western Dance</td>      
            </tr>      
            <tr className="success">
              <td>2</td>
              <td>Actattack – Theatre</td>
            </tr>
            <tr className="danger">
              <td>3</td>
              <td>Vox Anima – Western Music</td>
            </tr>
            <tr className="info">
              <td>4</td>
              <td>Checkmate – Chess Club</td>
            </tr>
            <tr className="warning">
              <td>5</td>
              <td>Star Trek – Astronomy (Paid Activity)</td>
            </tr>
            <tr className="active">
              <td>6</td>
              <td>Clicked! – Photography and Graphic Designing</td>
            </tr>
               <tr>
              <td>7</td>
              <td>Culinary Delight – Baking and Cookery</td>
            </tr> 
               <tr>
              <td>8</td>
              <td>Math Wizard – Math Club</td>
            </tr>  
               <tr>
              <td>9</td>
              <td>Science, Technology, Engineering, Arts, Mathematics – STEAM (only for classes VIII and IX)</td>
            </tr>      
          </tbody>
        </table>
        </div>
        </div>
      </div>
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default HobbyClubs
