import React from 'react'
import InnerHaeder from '../Component/InnerHaeder'
import Highlights from '../Component/Highlights' 
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const Sports = () => {
  return (
    <>
     <Highlights/>
     <InnerHaeder/>
     <div className="inner_page">
       <div className="container-fluid flwidth">
       <div className="bgimage">
        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> Co-Curricular  <i className="fas fa-angle-right"></i> Sports </span></h5>
        <h2>Sports</h2>
        </div>
        <p>Physical fitness is one of the most important elements of leading a healthy life. Physical education promotes the importance of inclusion of a regular fitness activity in the routine. This helps the students to maintain their fitness, develop their muscular strength, increase their stamina and thus stretch their physical abilities to an optimum level. Sports help inculcate discipline, self confidence, team spirit, sense of belonging, pride, achievement and self esteem. Students also understand that victory and defeat is an ongoing process. </p> 
  
        <div className="row img_data_sec">
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/BADMINTON.jpg">
            <img src="Images/BADMINTON.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a> 
            <h4>BADMINTON</h4></div></div> 
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/FOOTBALL.jpg">
            <img src="Images/FOOTBALL.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a> 
            <h4>FOOTBALL</h4></div></div> 
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/TABLE TENNIS.jpg">
            <img src="Images/TABLE TENNIS.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a> 
            <h4>TABLE TENNIS</h4></div></div> 
         
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/SKATING.jpg">
            <img src="Images/SKATING.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a> 
            <h4>SKATING</h4></div></div> 
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/YOGA.jpg">
            <img src="Images/YOGA.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a> 
            <h4>YOGA</h4></div></div> 
          <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/Taekwondo.jpg">
              <img src="Images/Taekwondo.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a> 
              <h4>Taekwondo</h4></div></div> 
        <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/CRICKET.jpg">
            <img src="Images/CRICKET.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a> 
            <h4>CRICKET</h4></div></div> 
          <div className="col-lg-4 col-md-6"><div className="box"> <a data-magnify="gallery" data-src="" data-group="a" href="Images/CHESS.jpg">
              <img src="Images/CHESS.jpg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></a> 
              <h4>CHESS</h4></div></div> 
         
        </div>
        <h2>Sports Achievements</h2>
        <h3>REPORT ON YOGA CHAMPIONSHIP - 2020</h3>   
              <p><strong>“The Key is not the will to win...everybody has that. It is the will to prepare to win that is important.”</strong></p>
              <p>Our students displayed their Flexibility, Stability & Talent at Concurrence 2020, Online Yoga Sports Competition, in association with UP State Yoga Association was Organsied by Jaypee Public School, Greater Noida on 28 - 29 August 2020 and Around 60 schools from UP had participated in this event. The students of LVISNE, brought laurels to the school by winning 1 silver and 2 bronze medals in different age categories.</p>
                <div className="row">
              <div className="col-md-12">
                  <div className="innertable">
                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Name</th>
                  <th>Class</th>
                  <th>Age Group Category</th>
                  <th>Position or Participation Certificate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1. </td>
                  <td>Aardhya Chauhan</td>
                  <td>KG - Jasmine</td>
                  <td>Under 5 - Girls</td>
                  <td>Participation Certificate</td>
                </tr>
                <tr>
                  <td>2. </td>
                  <td>Atharva Sharma</td>
                  <td>KG - Sunflower</td>
                  <td>Under 5 - Boys</td>
                  <td>Participation Certificate</td>
                </tr>
                <tr>
                  <td>3. </td>
                  <td>Anushka Gupta</td>
                  <td>Class 1 Flamingo</td>
                  <td>Under 7 - Girls</td>
                  <td>Participation Certificate</td>
                </tr>
                <tr>
                  <td>4. </td>
                  <td>Abhigyan Shekhar</td>
                  <td>Class 1 Dove</td>
                  <td>Under 7 - Boys</td>
                  <td>Participation Certificate</td>
                </tr>
                <tr>
                  <td>5. </td>
                  <td>Yashika Dubey</td>
                  <td>class 3 Augustus</td>
                  <td>Under 9 - Girls</td>
                  <td>Bronze</td>
                </tr>
                <tr>
                  <td>6. </td>
                  <td>Vyom Gupta</td>
                  <td>Class 3 Akbar</td>
                  <td>Under 9 - Boys</td>
                  <td>Participation Certificate</td>
                </tr>
                <tr>
                  <td>7. </td>
                  <td>Laasya Raghuvanshi</td>
                  <td>Class 6 Aries</td>
                  <td>Under 11 - Girls</td>
                  <td>Silver</td>
                </tr>
                <tr>
                  <td>8. </td>
                  <td>Shaurya Pratap Pathak</td>
                  <td>Class 5 Darwin</td>
                  <td>Under 11 - Boys</td>
                  <td>Participation Certificate</td>
                </tr>
                <tr>
                  <td>9. </td>
                  <td>Navika Sharma</td>
                  <td>Class 7 Marx</td>
                  <td>Under 13 - Girls</td>
                  <td>Bronze</td>
                </tr>
                <tr>
                  <td>10. </td>
                  <td>Nishchal Bhardwaj</td>
                  <td>Class 7 Seneca</td>
                  <td>Under 13 - Boys</td>
                  <td>Participation Certificate</td>
                </tr>
                <tr>
                  <td>11. </td>
                  <td>Kritika Sharma</td>
                  <td>Class 9 Maple</td>
                  <td>Under 15 - Girls</td>
                  <td>Participation Certificate</td>
                </tr>
                <tr>
                  <td>12. </td>
                  <td>Amitoj Singh</td>
                  <td>Class 8 Ramanujan</td>
                  <td>Under 15 - Boys</td>
                  <td>Participation Certificate</td>
                </tr>
             
              </tbody>
            </table>
                      </div>
              </div>
                    </div> 
              <div className="clr"></div>
              <h3>SKATING CHAMPIONS OF LVIS, NE - 2019</h3>       
              <p>“Victory belongs to those that believe in it the most and believe in it the longest.” Randall Wallace The Skating Champions of LVIS, Noida Extension brought laurels to the school by winning 4 gold, 1 silver, 4 bronze medals in different age categories and secured overall 3rd Position in the Inter-school Skating Championship organized by Azad Roller Sports Academy on Thursday 25, April 2019 at Chilla Sports Complex, Vasundhara Enclave Delhi. Around 30 schools participated from Delhi- NCR region.</p>
              
              <div className="clr"></div>
              <h3>YOGIS OF LVIS, NE BROUGHT LAURELS TO THE SCHOOL - 2019</h3>
              <p> The University of Delhi, North Campus, organized a 'Navyug Delhi Yoga Championship' in their Conference Center Hall, on 24th and 25th April 2019. Amidst the various participants, our school exhibited its presence with 29 students who participated energetically in the team and individual events. Our students participated with enthusiasm and zeal and brought laurels to the school by winning 3 gold, 4 silver and 2 bronze medals in different age categories. Our children bagged the 1st RUNNER UP trophy in the the championship. Our school witnessed a proud moment when Vyom Gupta, our student of class II- Ganges, won the Title Award of 'Mr. NAVYUG YOGI'. It was a great learning experience and a memorable moment for LVIS, Noida Extension. A complete blend of values and teamwork paved a path for us to achieve this applaud.</p>       
              
        </div>
    </div>
     <Footer/>
    </>
  )
}

export default Sports
