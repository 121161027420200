import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom'
const QuickGlancefifthslide = () => {
  const options = {
    nav: false,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
     };
  return (
    <>
      <OwlCarousel className="owl-carousel" {...options}>
        <div className="item">
          <img src="Images/dance.jpg" className="image" alt="Lotus Valley International School, Greater Noida"/>
        </div>
        <div className="item">
        <div className="draw-border">
        <h3><Link to="Dance">Dance</Link></h3>
        <p>Dance is a performing art form consisting of purposefully selected sequences of human movement. This movement has aesthetic and symbolic value, and is acknowledged as dance by performers and observers within a particular culture.</p>
        <div className="clr"></div>
      </div>
        </div>
      </OwlCarousel>
    </>
  );
};

export default QuickGlancefifthslide;
