import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
const InnerHaeder = () => {
  return (
     <>
     <div className="logo_sec">
     <div className="container">
       <div className="col-lg-12">
       <Link to="/"> 
           <img src="Images/logo.jpg" className="img-fluid  mx-auto d-block" alt="Lotus Valley International School, Greater Noida"/>
       </Link>
       </div>
     </div>
   </div>
   <div className="menu_sec">
   <div className="header">
   <Link to="/" className="header__logo">Menu</Link>
   <i className="header__toggle fas fa-bars" id="header-toggle"></i>
   <nav className="nav" id="nav-menu">
   <div className="nav__content bd-grid">
   <div className="nav__menu">
   <ul className="nav__list">
   <li className="nav__item"><Link to="/" className="nav__link">Home</Link></li>
   <li className="nav__item dropdown">
   <Link to="#" className="nav__link dropdown__link"> About Us <i className="dropdown__icon fas fa-angle-down"></i></Link>
   <ul className="dropdown__menu">
     <li className="dropdown__item"><NavLink to="/GeneralInfo" className="nav__link">General Info</NavLink></li>
     <li className="dropdown__item"><NavLink to="/OurMotto" className="nav__link">Our Motto</NavLink></li>
     <li className="dropdown__item"><NavLink to="/OurEmblem" className="nav__link">Our Emblem</NavLink></li>
     <li className="dropdown__item"><NavLink to="/OurPatron" className="nav__link">Our Patron</NavLink></li>
     <li className="dropdown__item"><NavLink to="/PrincipalDesk" className="nav__link">From Principal's Desk</NavLink></li>
     <li className="dropdown__item"><NavLink to="/MissionVision" className="nav__link">Mission &amp; Vision</NavLink></li>
     <li className="dropdown__item"><NavLink to="/OurAchievements" className="nav__link">Our Achievements</NavLink></li>
     <li className="dropdown__item"><NavLink to="/AdvisoryMembers" className="nav__link">Advisory Board Members</NavLink></li>
     <li className="dropdown__item"><NavLink to="/OurFaculty" className="nav__link">Our Faculty</NavLink></li>
     <li className="dropdown__item"><NavLink to="/StudentStrength"  className="nav__link">Student Strength</NavLink></li>
     <li className="dropdown__item"><NavLink to="/pdf/Annual-Report.pdf" className="nav__link" target="_blank">Annual Report</NavLink></li>
   </ul>
   </li>
   <li className="nav__item"><Link to="/pdf/MANDATORY-PUBLIC-DISCLOSURE.pdf" className="nav__link" target="_blank" >CBSE Disclosure</Link></li>
   <li className="nav__item dropdown">
   <Link to="#" className="nav__link dropdown__link" id="academics">Academics <i className="dropdown__icon fas fa-angle-down"></i></Link>
   <ul className="dropdown__menu">
     <li className="dropdown__item"> <NavLink to="/Curriculum" className="nav__link" >Curriculum </NavLink></li>
     <li className="dropdown__item"> <NavLink to="/TeachingMethodology" className="nav__link" >Teaching Methodology </NavLink></li>
     <li className="dropdown__item"> <NavLink to="/ITELearning" className="nav__link" >IT &amp; E-learning </NavLink></li>
     <li className="dropdown__item"> <NavLink to="/ExperientialLearning" className="nav__link">Experiential Learning </NavLink></li>
     <li className="dropdown__item"> <NavLink to="/SubjectShowcases" className="nav__link">Subject Showcases </NavLink></li>
     <li className="dropdown__item"> <NavLink to="/AssessmentEvaluation" className="nav__link">Assessment &amp; Evaluation </NavLink></li>
     <li className="dropdown__item"> <NavLink to="/Tc" className="nav__link">Transfer Certificate </NavLink></li>
     <li className="dropdown__item"> <NavLink className="nav__link" to="/pdf/Annual-Calendar-Grade-Pre-Nur-X.pdf" target="_blank">Annual Calendar </NavLink></li>
   </ul>
   </li>
   <li className="nav__item dropdown">
   <NavLink to="#" className="nav__link dropdown__link" id="curricular">Co-Curricular <i className="dropdown__icon fas fa-angle-down"></i></NavLink>
   <ul className="dropdown__menu">
     <li className="dropdown__item"><NavLink to="/Sports" className="nav__link">Sports </NavLink></li>
     <li className="dropdown__item"><NavLink to="/ArtCraft" className="nav__link">Art &amp; Craft </NavLink></li>
     <li className="dropdown__item"><NavLink to="/Music" className="nav__link">Music </NavLink></li>
     <li className="dropdown__item"><NavLink to="/Dance" className="nav__link">Dance </NavLink></li>
     <li className="dropdown__item"><NavLink to="/HobbyClubs" className="nav__link">Hobby Clubs </NavLink></li>
     <li className="dropdown__item"><NavLink to="/pdf/List-of-Special-Assemblies.pdf" className="nav__link" target="_blank">Assemblies </NavLink></li>
     <li className="dropdown__item"><NavLink to="/TrainingTrainers" className="nav__link">Training The Trainers </NavLink></li>
     <li className="dropdown__item"><NavLink to="/CommunityServiceSocialWork" className="nav__link">Community Service and Social Work </NavLink></li>
   </ul>
   </li>
   <li className="nav__item dropdown">
   <NavLink to="#" className="nav__link dropdown__link" id="admission">Admission <i className="dropdown__icon fas fa-angle-down"></i></NavLink>
   <ul className="dropdown__menu">
     <li className="dropdown__item"><NavLink to="/AdmissionProcess" className="nav__link">Admission Process </NavLink></li>
     <li className="dropdown__item"><NavLink to="/pdf/admission.pdf" className="nav__link" target="_blank">Admission Form </NavLink></li>
     <li className="dropdown__item"><NavLink to="/pdf/Registration-Form.pdf" className="nav__link" target="_blank">Registration Form </NavLink></li>
     <li className="dropdown__item"><NavLink to="/pdf/LVS-Prospectus.pdf" className="nav__link" target="_blank">Prospectus </NavLink></li>  
     <li className="dropdown__item"><NavLink to="/FeeStructure" className="nav__link">Fee Structure </NavLink></li>
   </ul>
   </li>
   <li className="nav__item dropdown">
   <NavLink to="#" className="nav__link dropdown__link">CBSE Documents <i className="dropdown__icon fas fa-angle-down"></i></NavLink>
   <ul className="dropdown__menu">
     <li className="dropdown__item"><NavLink className="nav__link" to="/pdf/Book-list.pdf" target="_blank">Booklist</NavLink></li>
     <li className="dropdown__item"><NavLink className="nav__link" to="/pdf/declaration.pdf" target="_blank">Declaration </NavLink></li>
     <li className="dropdown__item"><NavLink className="nav__link" to="/CommitteeDetails">Committee Details</NavLink></li>
     <li className="dropdown__item"><NavLink className="nav__link" to="/pdf/Annual-Calendar-Grade-Pre-Nur-X.pdf" target="_blank">Annual Calendar</NavLink></li>
     <li className="dropdown__item"><NavLink className="nav__link" to="/pdf/SMC.pdf" target="_blank">School Management Committee</NavLink></li>
     <li className="dropdown__item"><NavLink className="nav__link" to="/pdf/Self-Affidavit.pdf" target="_blank">Self Affidavit</NavLink></li>
   </ul>
   </li>
   <li className="nav__item"><NavLink className="nav__link" to="/Circulars">Circulars</NavLink></li>
   <li className="nav__item"><NavLink className="nav__link" to="/Infrastructure">Infrastructure</NavLink></li>
   <li className="nav__item dropdown">
   <NavLink to="#" className="nav__link dropdown__link">Gallery <i className="dropdown__icon fas fa-angle-down"></i></NavLink>
   <ul className="dropdown__menu">
     <li className="dropdown__item"><NavLink className="nav__link" to="/PhotoGallery">Photo Gallery</NavLink></li>
     <li className="dropdown__item"><NavLink className="nav__link" to="/Media">Media</NavLink></li>
   </ul>
   </li>
   <li className="nav__item dropdown">
   <NavLink to="#" className="nav__link dropdown__link">Careers <i className="dropdown__icon fas fa-angle-down"></i></NavLink>
   <ul className="dropdown__menu">
     <li className="dropdown__item"><NavLink className="nav__link" to="/JobOportunities">Job Opportunities</NavLink></li>
     <li className="dropdown__item"><NavLink className="nav__link" to="/pdf/application-form.pdf" target="_blank">Application Form</NavLink></li>
   </ul>
   </li>
   <li className="nav__item"><NavLink className="nav__link" to="/Contact">Contact</NavLink></li>
   </ul>
   </div>
   </div>
   </nav>
   </div>
   </div>
  

     </>
  )
}

export default InnerHaeder
