import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from 'react-router-dom'
const QuickGlancenineslide = () => {
  const options = {
    nav: false,
    items:1,
    autoplay: true,
    smartSpeed: 1000,
     };
  return (
    <>
      <OwlCarousel className="owl-carousel" {...options}>
        <div className="item">
             <img src="Images/faculty.jpg" className="image" alt="Lotus Valley International School, Greater Noida"/>
        </div>
        <div className="item">
        <div className="draw-border">
        <h3><Link to="OurFaculty">Faculty</Link></h3>
        <p>Academic personnel, also known as faculty member or member of the faculty or academics or academic staff, are vague terms that describe teaching or research staff of a university, college, school, or research institute.</p>
       
      </div>
        </div>
      </OwlCarousel>
    </>
  );
};

export default QuickGlancenineslide;
