import { useEffect, useState } from "react";
import InnerHaeder from '../Component/InnerHaeder';
import Highlights from '../Component/Highlights';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
import { getNewsInner } from "../Service/Api";

const News = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getNewsInner(); 
            setData(galleryData); 

            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const year = new Date(item.fromDate).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);

            const uniqueMonths = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.fromDate) {
                        const month = new Date(item.fromDate).getMonth() + 1;
                        return [month];
                    } else {
                        return [];
                    }
                }))
            );
            const monthOptions = uniqueMonths.sort((a, b) => a - b).map(month => monthNames[month - 1]);
            setMonthOptions(["All", ...monthOptions]);

            // Determine the current year and fallback year
            const currentYear = new Date().getFullYear();
            const previousYear = currentYear - 1;
            const currentYearDataAvailable = galleryData.some(item => new Date(item.fromDate).getFullYear() === currentYear);

            if (currentYearDataAvailable) {
                setSelectedYear(`${currentYear}`);
            } else {
                setSelectedYear(`${previousYear}`);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const filterByYearAndMonth = () => {
            let filteredData = data;
            
            // Check if there is data for the selected year
            let yearDataAvailable = false;

            if (selectedYear && selectedYear !== "All") {
                filteredData = filteredData.filter((item) => {
                    if (item.fromDate && item.fromDate.trim() !== "") {
                        const year = new Date(item.fromDate).getFullYear();
                        if (parseInt(selectedYear) === year) {
                            yearDataAvailable = true;
                            const month = new Date(item.fromDate).getMonth() + 1;
                            return (selectedMonth === "All" || parseInt(selectedMonth) === month);
                        }
                        return false;
                    } else {
                        return false;
                    }
                });

                // If no data available for the selected year, fall back to the previous year
                if (!yearDataAvailable) {
                    const previousYear = (parseInt(selectedYear) - 1).toString();
                    setSelectedYear(previousYear);
                    return; // Exit to trigger a re-render with the previous year
                }
            } else {
                // Handle case where "All" is selected
                filteredData = filteredData.filter(item => item.fromDate);
            }

            // Filter by month
            if (selectedMonth !== "All") {
                filteredData = filteredData.filter((item) => {
                    if (item.fromDate && item.fromDate.trim() !== "") {
                        const month = new Date(item.fromDate).getMonth() + 1;
                        return parseInt(selectedMonth) === month;
                    } else {
                        return false;
                    }
                });
            }

            // Filter by search query
            if (searchQuery) {
                filteredData = filteredData.filter((item) =>
                    item.title.toLowerCase().includes(searchQuery.toLowerCase())
                );
            }

            setFilteredData(filteredData);
        };

        filterByYearAndMonth();
    }, [selectedYear, selectedMonth, searchQuery, data]);

    const emptyArray = [
        {
            description: "Stay tuned for more information about latest updates.",
            attachments: ["director.jpg"]
        }
    ];

    return (
        <>
            <Highlights />
            <InnerHaeder />
            <div className="inner_page">
                <div className="container-fluid flwidth innerevent_sec">
                    <div className="bgimage">
                        <h5><Link to="/"> Home <i className="fas fa-angle-right"></i> </Link><span> News </span></h5>
                        <h2> News</h2>
                    </div>
                    <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count: {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select
                                    value={selectedYear || "All"}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect">
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={index + 1}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row event_news_inner">
                        {filterData.length > 0 ? (
                            filterData.map((item, index) => (
                                <div className="col-lg-6 col-md-12" key={index}>
                                    <div className="data_sec">
                                        <div className="overlay_news">
                                            <p>{item.description}</p>
                                        </div>
                                        <div className="date_hidn">
                                            <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.image}`} alt="Lotus Valley International School, Greater Noida" />
                                        </div>
                                        <div className="data_scrol">
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                            <div className="clr"></div>
                                        </div>
                                        {item.attachments.length > 0 && (<Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments}`} target="_blank" className="read_class tooltip">Attachment <i className="fas fa-arrow-right"></i> <span className="tooltiptext">View Attachment</span></Link>)}
                                        <div className="clr"></div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            emptyArray.map((data, index) => (
                                <div className="col-lg-6 col-md-12" key={index}>
                                    <div className="data_sec">
                                        <div className="overlay_news">
                                            <p>{data.description}</p>
                                        </div>
                                        <div className="date_hidn"><img src="Images/news.jpg" alt="Lotus Valley International School, Greater Noida" /></div>
                                        <div className="data_scrol">
                                            <h3>News Heading - 1</h3>
                                            <p>{data.description}</p>
                                            <div className="clr"></div>
                                        </div>
                                        <Link to="#" target="_blank" className="read_class tooltip">Attachment <i className="fas fa-arrow-right"></i> <span className="tooltiptext">View Attachment</span></Link>
                                        <div className="clr"></div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default News;
