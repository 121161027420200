import React from 'react'
import OwlCarousel from 'react-owl-carousel';
const Partners = () => {
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 4,
            },
            1000: {
                items:6,
    
            }
        },
    };
  return (
     <>
     <OwlCarousel className="owl-carousel" {...options}>
        <div className='item'>
            <div className="partnerbox"><img src="Images/partners_1.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
        <div className='item'>
           <div className="partnerbox"><img src="Images/partners_2.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
        <div className='item'>
           <div className="partnerbox"><img src="Images/partners_3.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
        <div className='item'>
            <div className="partnerbox"><img src="Images/partners_4.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
        <div className='item'>
            <div className="partnerbox"><img src="Images/partners_5.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
        <div className='item'>
            <div className="partnerbox"><img src="Images/partners_6.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
        <div className='item'>
            <div className="partnerbox"><img src="Images/partners_7.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
        <div className='item'>
            <div className="partnerbox"><img src="Images/partners_8.jpeg" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
        <div className='item'>
            <div className="partnerbox"><img src="Images/partners_9.png" className="img-fluid" alt="Lotus Valley International School, Greater Noida"/></div>
        </div>
 </OwlCarousel>
     </>
  )
}

export default Partners
